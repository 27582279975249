
import { computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import useModel from '@/hooks/useModel'
import clickOutside from '@/views/Payment/directive/clickOutside'
import moment from 'moment'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    },
    formator: {
      type: Function as PropType<(text: string) => string >,
      requried: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemType: {
      type: String,
      default: 'input'
    },
    isAllowClear: {
      type: Boolean,
      default: false
    },
    showFunc: {
      type: Function
    }
  },
  directives: {
    clickOutside
  },
  emits: [
    'click-outside'
  ],
  setup(props, {emit}) {
    const valueProp = useModel(props, 'value')
    const inEditting = ref(false)

    const handleClick = () => {
      if (!props.disabled) {
        inEditting.value = true
      }
    }

    const handleClickOutSide = (event: Event) => {
      if (inEditting.value) {
        // inEditting.value = false
        emit('click-outside', (type: boolean) => {
          console.log(type, 8989)
          inEditting.value = type
        })
      }
    }

    const text = computed(() => {
      if (props.formator) {
        return props.formator(props.value)
      } else {
        return props.value
      }
    })

    return {
      moment,
      handleClick,
      handleClickOutSide,
      valueProp,
      inEditting,
      text
    }
  }
})
