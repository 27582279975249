
import { defineComponent, reactive } from 'vue'
import useFloorPlanDays from "@/hooks/SpotCheckingConfiguration/useFloorPlanDays";
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-325
import TableWrapper from "@/components/TableWrapper.vue";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import clickOutside from '@/views/Planning/directive/clickOutside'
import EditableView from '@/views/Payment/components/EditableView.vue'

export default defineComponent({
  name: 'payoutPreparation',
  components: {
    TableWrapper,
    EditableView
  },
  directives: {
    clickOutside
  },
  props: {},
  setup() {
    const {
      year,
      basicColData,
      formRef,
      form,
      saveCell,
      saveClick
    } = useFloorPlanDays()

    const rules = reactive({
      floorPlanDay: [
        {
          required: true,
          message: "Please input Bill Rate",
          trigger: "change",
        },
        {
          pattern: /^([1-9]\d*|0)$/,
          message: "Bill Rate format error",
          trigger: "change",
        },
      ],
      floorActiveDate: [
        {
          required: true,
          message: "Please select Active Date",
          trigger: "change",
          type: 'object'
        }
      ]
    });
    
    return {
      tableWidth,
      tableHeight,
      year,
      basicColData,
      formRef,
      form,
      rules,
      saveCell,
      // editCell,
      saveClick,
      toThousandsAndTwoDecimal
    }
  }
})
