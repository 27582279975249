import type { DirectiveBinding } from 'vue'

export default {
  mounted(el: HTMLElement & {__vueClickOutside__: (...param: any) => any}, binding: DirectiveBinding) {
    function documentHandler (e: any) {
      if (el.contains(e.target)) {
        return false;
      }
      binding.value(e)
    }
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('mousedown', documentHandler)
  },
  unmounted (el: any) {
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__;
  }
}