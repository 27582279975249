
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: "TableWrapper",
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        }
    })
