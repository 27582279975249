import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-view", {disabled: _ctx.disabled}])
  }, [
    _withDirectives(_createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, _toDisplayString(_ctx.itemType === 'date' ?
        _ctx.moment(_ctx.text).format('YYYY/MM/DD') :
        (_ctx.showFunc ? _ctx.showFunc(_ctx.text) : _ctx.text)), 513), [
      [_vShow, !_ctx.inEditting]
    ]),
    (_ctx.itemType === 'input')
      ? _withDirectives((_openBlock(), _createBlock(_component_a_input, {
          key: 0,
          value: _ctx.valueProp,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueProp) = $event))
        }, null, 8, ["value"])), [
          [_vShow, _ctx.inEditting]
        ])
      : (_ctx.itemType === 'date')
        ? _withDirectives((_openBlock(), _createBlock(_component_a_date_picker, {
            key: 1,
            value: _ctx.valueProp,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valueProp) = $event)),
            "allow-clear": _ctx.isAllowClear,
            format: "YYYY/MM/DD"
          }, null, 8, ["value", "allow-clear"])), [
            [_vShow, _ctx.inEditting]
          ])
        : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, _ctx.handleClickOutSide]
  ])
}